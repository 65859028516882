<template>
  <div>Hi This is test page</div>
</template>
<script>
export default {
  created() {
    console.log("This is Test Page");
  },
};
</script>
